import React, { Component } from 'react';
import gql from 'graphql-tag';
import moment from 'moment';

import { Query } from 'z-frontend-network';
import { Banner } from 'z-frontend-composites';
import { isCancellationRequestActive } from 'z-frontend-hr-components/src/renewal-notice/utils';
import { TextBlock } from 'zbase';
import { v3BasePlanSkus } from 'z-frontend-hr-components';
import { getEventLogger } from 'z-frontend-app-bootstrap';

import { CancellationNoticeQuery } from '../../gqlTypes';
import { CancellationRequestStatus, CheckoutPackageProductSku } from '../../../schema/schemaTypes';

const FULL_COMPANY_ADMIN = 'is_full_company_admin';

function shouldShowCancellationBanner(data: CancellationNoticeQuery.Query) {
  const { rolesPermissionsData, activeBillingDetails } = data;

  const isFullAdmin = rolesPermissionsData?.grants?.hasOwnProperty(FULL_COMPANY_ADMIN);
  if (!isFullAdmin) {
    return false;
  }

  const { version: billingVersion } = activeBillingDetails;
  const isV4Billing = billingVersion === 4;
  const baseProductSku = activeBillingDetails?.planDetails?.name;
  const isV3BasePlan = v3BasePlanSkus.includes(baseProductSku as CheckoutPackageProductSku);
  const isV3BasePlanOrV4Billing = isV3BasePlan || isV4Billing;

  if (!isV3BasePlanOrV4Billing) {
    return false;
  }

  const cancellationRequest = data.dashboard?.company?.cancellationRequest;
  if (!cancellationRequest) {
    return false;
  }

  if (
    !isCancellationRequestActive(cancellationRequest) &&
    cancellationRequest.status !== CancellationRequestStatus.completed
  ) {
    // If the cancellation request is not completed, don't show the banner
    return false;
  }

  return true;
}

export default class CancellationRequestBanner extends Component<{}> {
  render() {
    const eventLogger = getEventLogger();

    return (
      <Query<CancellationNoticeQuery.Query>
        query={cancellationNoticeQuery}
        handleLoading={false}
        handleError={false}
        isBackgroundQuery
      >
        {({ data, loading, error }) => {
          if (error) {
            eventLogger.logError(error);
            return null;
          }

          if (loading || !data || !shouldShowCancellationBanner(data)) {
            return null;
          }

          const cancellationRequest = data.dashboard?.company?.cancellationRequest;

          return (
            <Banner type="info" mb={4}>
              <TextBlock>
                We’ve received your cancellation and your contract will end on{' '}
                {moment(cancellationRequest.effectiveCancellationDate).format('MMMM Do, YYYY')}. Thank you for the
                opportunity to support your needs. We wish you and your employees the very best.
              </TextBlock>
            </Banner>
          );
        }}
      </Query>
    );
  }
}

const cancellationNoticeQuery = gql`
  query CancellationNoticeQuery {
    dashboard {
      id
      company {
        id
        cancellationRequest(statuses: [completed]) {
          id
          status
          effectiveCancellationDate
        }
      }
    }
    rolesPermissionsData {
      grants
    }
    activeBillingDetails {
      version
      planDetails {
        name
      }
    }
  }
`;
